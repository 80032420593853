export const stuff = [
  {
    link: 'https://www.instagram.com/p/CZ2SDSDlBtW/',
    text: 'Wife Guys: Thoughts on Love, Marriage, and Long Term Commitment. (The Drunken Canal)',
  },
  {
    link: 'https://dirt.substack.com/p/dirt-comic-stans',
    text: 'A brief look at a misunderstood typeface, Comic Sans. (Dirt)',
  },
  {
    link: '/2022-album-list',
    text: 'My 2022 Albums of the Year',
    isInternal: true,
  },
  {
    link: '/2021-album-list',
    text: 'My 2021 Albums of the Year',
    isInternal: true,
  },
  {
    link: 'https://mailchi.mp/publicannouncement/wednesday-stills-with-tyler-bainbridge',
    text: '2021 Stills (Public Announcement)',
  },
];
